/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { CardNumberElement, useElements } from '@stripe/react-stripe-js'
import {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  StripeCardNumberElement,
} from '@stripe/stripe-js'
import { ApplePay, Close, CreditCard, GooglePay } from 'assets/images'
import AlertDialog from 'components/AlertDialog/Index'
import Button from 'components/Button/Index'
import OrderDiffAlertDialog from 'components/OrderDiffAlertDialog/Index'
import StripeCardCVV from 'components/StripeComponents/StripeCardCVV'
import StripeCardExpiry from 'components/StripeComponents/StripeCardExpiry'
import StripeCardNumber from 'components/StripeComponents/StripeCardNumber'
import { PAYMENT_OPTIONS } from 'enums/payment-options'
import { TRACKING_EVENTS } from 'enums/tracking-options'
import { setPaymentInProcess } from 'features/paymentProgress/paymentProgress'
import { restuarantData } from 'features/restaurant/restaurant'
import { tabData, tabDetail } from 'features/tab/tab'
import { userData } from 'features/user/user'
import { useFormik } from 'formik'
import { useSplitDetail } from 'hooks/useSplitDetail'
import { IGetToBePaidResponse, IPaymentValidation } from 'interfaces/payment'
import { IRestaurantDetailData } from 'interfaces/restaurant'
import { IStripePayment } from 'interfaces/stripe'
import {
  IOrderDetail,
  IPaymentOrder,
  ITabDetail,
  ITabPaymentItem,
  IValidationRes,
} from 'interfaces/tab'
import {
  IItemCompare,
  IOrderCompare,
  IPaymentItemList,
  ITabsPaymentRequest,
} from 'interfaces/tabs'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import guestService from 'services/guest.service'
import paymentService from 'services/payment.service'
import tabService from 'services/tab.service'
import storageHelper from 'utility/browserStorageHelper'
import * as yup from 'yup'
import { useStripe } from 'hooks/useStripe'
import { buildPaymentOrder } from 'utility/payment'

interface Props {
  open: boolean
  paymentAmount: number
  tip: number
  totalToBePaid: number
  onClose?: VoidFunction
  payToBeDoneRes?: IGetToBePaidResponse
  isCustomTip?: boolean
  customTip?: number
  tipPercentage?: number
  itemListPay?: IPaymentItemList[]
  promocodeId?: number
  promocodeAmount?: number
  noOfPayingGuest: number
}

const PaymentOptionPanels = {
  panel1: 'panel1',
  panel2: 'panel2',
  panel3: 'panel3',
  panel4: 'panel4',
}

interface IPayment {
  data?: ITabsPaymentRequest
  intentId: string
  chargeId: string
  description: string
  cardType: string
  paySubTotal: number
  payTipAmount: number
  payTaxAmount: number
  toBePaid: number
  paymentOrder: IPaymentOrder[]
}

interface IStripeResult {
  secret: string
  intentId: string
  description: string
  cardType: string
  chargeId: string
}

function PaymentProcessDialog(props: Props) {
  const {
    open,
    onClose: onCloseParent,
    paymentAmount,
    payToBeDoneRes,
    totalToBePaid,
    tip,
    isCustomTip,
    customTip,
    tipPercentage,
    itemListPay,
    promocodeId,
    promocodeAmount,
    noOfPayingGuest,
  } = props

  const { splitType, processableSplitType } = useSplitDetail()
  const { stripe, abortPaymentProcess, availableOption, paymentRequest } =
    useStripe()

  const dispatch = useDispatch()
  const tabDetails = useSelector(tabDetail)
  const tabDetaildata = useSelector(tabData)
  const subTotalOfTab = tabDetails?.orders.reduce(
    (prev: number, next: IOrderDetail) => {
      return prev + next.subTotal
    },
    0
  )
  const taxOfTab = tabDetails?.orders.reduce(
    (prev: number, next: IOrderDetail) => {
      return prev + next.tax
    },
    0
  )
  const [isCardNumberValid, setisCardNumberValid] = React.useState(true)
  const [btnDisable, setBtnDisable] = React.useState(false)
  const [btnClicked, setBtnClicked] = useState(false)
  const [isCvvValid, setIsCvvValid] = React.useState(true)
  const [paymentOption, setPaymentOption] = useState<PAYMENT_OPTIONS>(0)
  const [isExpValid, setIsExpValid] = React.useState(true)
  const [subTotal, setSubTotal] = React.useState(subTotalOfTab)
  const [tax, setTax] = React.useState(taxOfTab)
  const restaurantData: IRestaurantDetailData = useSelector(restuarantData)
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const estId = sessionStorage.getItem('establishmentId')
  const tabsId = sessionStorage.getItem('tabId')
  const [serviceCharge, setServiceCharge] = React.useState(0)
  const [logsData, SetLogs] = useState('')
  const [splitMsg, setSplitMsg] = useState('')
  const [openAlertDialog, setOpenALertDialog] = useState(false)
  const [isPaymentProcessValid, setisPaymentProcessValid] = useState(false)
  const [openOrderDiffAlertDialogMesssage, setOpenOrderDiffALertDialogMessage] =
    useState(false)
  const [paymentFailed, setPaymentFailed] = useState(false)
  const setLoaderForPaymentProcess = (flag: boolean, method: string) => {
    dispatch(setPaymentInProcess(flag))
    console.log(`setPaymentInProcess=${flag}`, method)
  }
  const [openOrderDiffAlertDialog, setOpenOrderDiffALertDialog] =
    useState(false)
  const defaultOptions = {
    country: 'CA',
    currency: restaurantData?.currencyName.toLowerCase(),
    total: {
      label: 'Demo total',
      amount: 100,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  }

  const user = useSelector(userData)
  const elements = useElements()
  const getDefaultValue = () => {
    return {
      firstName: user?.firstName ? user?.firstName : '',
      lastName: user?.lastName ? user?.lastName : '',
      emailAddress: user?.emailAddress ? user?.emailAddress : '',
      loginId: user?.loginId ? user?.loginId : storageHelper.getLoginId(),
    }
  }

  const onClose = (payOption: PAYMENT_OPTIONS) => {
    abortPaymentProcess(payOption)
    onCloseParent()
  }

  const navigate = useNavigate()
  let toBePaidResponse
  const defaultStripeResult: IStripeResult = {
    secret: '',
    intentId: '',
    description: '',
    cardType: '',
    chargeId: '',
  }
  const sendStripePaymentRequest = async (payloads: IStripePayment) => {
    const response = await paymentService.addStripePayment(payloads)
    const result: IStripeResult = {
      ...defaultStripeResult,
    }
    if (response?.data?.data.clientSecret && !response?.data?.data.intentId) {
      result.secret = response.data.data.clientSecret
    } else if (response?.data?.data?.intentId) {
      result.intentId = response.data.data.intentId
      result.chargeId = response.data.data.chargeId
    }
    result.description = response.data.data.description
    return result
  }

  const getPaymentData = () => {
    const toBePaidData: IGetToBePaidResponse = payToBeDoneRes
    const paySubTotal = Number(toBePaidData.totalBillAmount.toFixed(2))
    const payTaxAmount = Number(toBePaidData.totalTax.toFixed(2))

    const paymentData: IPayment = {
      data: null,
      intentId: '',
      chargeId: '',
      description: '',
      cardType: '',
      paySubTotal,
      payTaxAmount,
      payTipAmount: tip,
      toBePaid: totalToBePaid,
      paymentOrder: buildPaymentOrder(
        toBePaidData.paymentToBeDones,
        toBePaidData.totalBillAmount,
        tip
      ),
    }
    return paymentData
  }

  const proceedFor3DSecure = async (secret: string) => {
    const response = await stripe.confirmCardPayment(secret)
    if (response.error) {
      toast.error(response.error?.message || 'Something went wrong!')
      setLoaderForPaymentProcess(false, 'proceedFor3DSecure')
      await tabService.lockTab({
        tabId: tabDetails?.tabId,
        isLock: false,
      })
      return ''
    }
    return response?.paymentIntent?.id
  }
  const sendPaymentRequest = async (
    amount: number,
    currency: string,
    tabId: string
  ) => {
    const checkIfStripeAvailable = () => {
      if (!stripe || !elements) return false
      return true
    }

    if (!checkIfStripeAvailable()) {
      return defaultStripeResult
    }

    const createStripePaymentMethod = async () => {
      const paymentMethods = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
      })
      paymentService.paymentData = paymentMethods
      const baseError = paymentMethods.error
      const { paymentMethod } = paymentMethods
      if (baseError || !paymentMethod) {
        setLoaderForPaymentProcess(false, 'createStripePaymentMethod1')
        await tabService.lockTab({
          tabId: tabDetails?.tabId,
          isLock: false,
        })
        if (!baseError?.code?.includes('incomplete')) {
          toast.error(baseError.message)
          setLoaderForPaymentProcess(false, 'createStripePaymentMethod2')
          await tabService.lockTab({
            tabId: tabDetails?.tabId,
            isLock: false,
          })
        }
        return null
      }
      return {
        paymentMethodId: paymentMethod?.id,
        cardType: paymentMethod?.card?.brand,
      }
    }

    const { paymentMethodId, cardType } = await createStripePaymentMethod()
    const selectedOrders = tabDetails?.orders.filter(
      (d) =>
        d.isSelected &&
        payToBeDoneRes.paymentToBeDones
          .map((x) => x.orderId)
          .includes(d.orderId)
    )
    const orderIds =
      selectedOrders?.map((order) => order.orderId).join(',') || '0'
    const posOrderIds =
      selectedOrders?.map((order) => order.posOrderId).join(',') || '0'
    const payload = {
      paymentMethodId,
      amount,
      currency: restaurantData?.currencyName.toLowerCase(),
      tabId,
      posOrderId: posOrderIds,
      establishmentId: estId,
      establishmentName: restaurantData?.establishmentName,
      orderId: orderIds,
      restaurantId: restaurantData?.restaurantId?.toString(),
      restaurantName: restaurantData?.restaurantName,
    }
    const stripeResult = await sendStripePaymentRequest(payload)
    stripeResult.cardType = cardType
    if (stripeResult.secret) {
      const intentId = await proceedFor3DSecure(stripeResult.secret)
      if (!intentId) {
        setLoaderForPaymentProcess(false, 'sendPaymentRequest1')
        await tabService.lockTab({
          tabId: tabDetails?.tabId,
          isLock: false,
        })
      } else {
        stripeResult.intentId = intentId
      }
    }
    if (!stripeResult.intentId) {
      setLoaderForPaymentProcess(false, 'sendPaymentRequest2')
      await tabService.lockTab({
        tabId: tabDetails?.tabId,
        isLock: false,
      })
    }
    return stripeResult
  }

  const updatePaymentDetailInFasTab = async (params: IPayment) => {
    const {
      data,
      intentId,
      chargeId,
      cardType,
      payTipAmount,
      payTaxAmount,
      toBePaid,
      paymentOrder,
    } = params
    console.log(`Update data in db start`)
    const payDetails = paymentService.paymentData.paymentMethod
    // bill no and paymentId to be processed from back end
    try {
      const objProcessPayment = {
        paymentOrder: paymentService.paymentOrder,
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
        guestId: data.guestId || 0,
        postalCode: payDetails?.billing_details.address?.postal_code,
        loginId: data.loginId,
        tabId: tabDetails?.tabId,
        paymentMethodId: 2,
        cardType,
        transactionId: intentId,
        paymentGatewayTxnId: chargeId,
        splitTypeName: processableSplitType,
        items: itemListPay,
        isCustomTip,
        tipPercentage,
        serviceCharge: Number(payToBeDoneRes.totalServiceCharges.toFixed(2)),
        establishmentId: Number(estId),
        actionId: TRACKING_EVENTS.PAYMENT_COMPLETED,
        tableId: storageHelper.getTableId(),
        tabConnectionId: Number(sessionStorage.getItem('tabConnectionId')),
        transactionType:
          paymentOption === PAYMENT_OPTIONS.CARD
            ? 'Card'
            : paymentOption === PAYMENT_OPTIONS.GOOGLE_PAY
            ? 'Google Pay'
            : 'Apple Pay',
        paymentDate: new Date(payDetails.created * 1000).toISOString(),
        accountNo: payDetails.card.last4,
        restaurantId: restaurantData?.restaurantId,
        orderId: paymentOrder[0].orderId,
        isAddGuestRequired: !user?.loginId || !data.guestId,
        posTableId: Number(sessionStorage.getItem('posTableId')),
        total: toBePaid,
        totalTip: Number(payTipAmount.toFixed(2)),
        totalTax: payTaxAmount,
        paymentModeId: Number(splitType),
        promocodeId,
        promoAmount: promocodeAmount,
        noOfPayingGuest,
      }
      let result

      try {
        result = await paymentService.processPaymentInFasTab(objProcessPayment)
      } catch {
        guestService.addLogAsync(
          `second time payment TabId: ${objProcessPayment.tabId} Amount: ${objProcessPayment.orderId}`
        )
        result = await paymentService.processPaymentInFasTab(objProcessPayment)
      }

      localStorage.removeItem('IsPaymentProgress')
      setLoaderForPaymentProcess(false, 'updatePaymentDetailInFasTab')
      navigate('/payment-successful', {
        state: {
          data,
          billNo: result.data.data.billNo,
          ammountpaid: toBePaid - (promocodeAmount > 0 ? promocodeAmount : 0),
          paymentId: result.data.data.tabPaymentId,
          guestId: result.data.data.guestId,
          establishmentId: Number(estId),
          tabId: Number(tabsId),
        },
      })
    } catch (error) {
      toast.error(
        'There was an issue with your payment. Please check with your server, and rest assured any charges will be refunded.'
      )

      guestService.addLogAsync(`Guest :Error in FT Payment ${error}`)
      setLoaderForPaymentProcess(false, 'updatePaymentDetailInFasTab')
    }
  }

  const completePayment = async (payload: IStripePayment) => {
    const stripeResult = await sendStripePaymentRequest(payload)
    if (stripeResult.secret) {
      stripeResult.intentId = await proceedFor3DSecure(stripeResult.secret)
    }
    if (!stripeResult.intentId) {
      toast.error(stripeResult.description)
      setLoaderForPaymentProcess(false, 'Gpay or ApplePay')
      guestService.addLogAsync(`Stripe: Intent is empty ${payload}`)
      await tabService.lockTab({
        tabId: tabDetails?.tabId,
        isLock: false,
      })
      throw new Error('Intent is nullish')
    }
    return stripeResult
  }
  const getValidationPayload = () => {
    let ftAmount = 0
    const selectedOrders = tabDetails.orders.filter(
      (d) =>
        d.isSelected &&
        d.isOpen &&
        payToBeDoneRes.paymentToBeDones
          .map((x) => x.orderId)
          .includes(d.orderId)
    )
    const ordersForCompare: IOrderCompare[] = []
    for (const element of selectedOrders) {
      ordersForCompare.push({
        orderId: element.orderId,
        posOrderId: element.posOrderId,
        totalServiceCharges: element.totalServiceCharges,
        omnivoreTicketNo: element.omnivoreTicketNo,
        items: element.items.map((el) => {
          return {
            itemId: el.itemId,
            posItemId: el.posItemId,
            name: el.name,
            quantity: el.quantity,
          } as IItemCompare
        }),
      })
      try {
        // eslint-disable-next-line no-loop-func
        const orderIndex = tabDetails?.tabPayments?.orderPayments.findIndex(
          (ele) => ele.orderId === element.orderId
        )
        if (orderIndex >= 0) {
          ftAmount +=
            tabDetails?.tabPayments?.orderPayments[orderIndex]
              ?.orderPaymentAmount
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Pay-Check: ', error)
        guestService.addLogAsync(`Guest :Error in FT Payment ${error}`)
      }
    }
    const posOrderId =
      tabDetails?.orders
        ?.filter((d) => d.isSelected && d.isOpen)
        .map((order) => order.posOrderId) || []
    const payload: IPaymentValidation = {
      itemIds: itemListPay?.map((d) => d.itemId),
      tabId: tabDetails?.tabId,
      isPaymentRequest: true,
      splitType: Number(sessionStorage.getItem('paymentModeId')),
      posOrderIds: posOrderId,
      establishmentId: Number(estId),
      posTableId: tabDetaildata.tableId,
      totalFTPaymentOnSelectedOrderIds: ftAmount,
      selectedOrders: ordersForCompare,
      noOfPayingGuest: noOfPayingGuest ?? 0,
    }
    return payload
  }

  const onPaymentMethod = async (
    e: PaymentRequestPaymentMethodEvent,
    params: IPayment
  ) => {
    localStorage.setItem('IsPaymentProgress', 'true')
    const { complete, paymentMethod, payerName, payerEmail } = e
    console.log('gpay paymentData', e)
    paymentService.paymentData = e
    const guestId = user ? user.guestId : null

    const selectedOrders = tabDetails?.orders.filter(
      (d) =>
        d.isSelected &&
        payToBeDoneRes.paymentToBeDones
          .map((x) => x.orderId)
          .includes(d.orderId)
    )
    const orderIds =
      selectedOrders?.map((order) => order.orderId).join(',') || '0'
    const posOrderIds =
      selectedOrders?.map((order) => order.posOrderId).join(',') || '0'
    const payload: IStripePayment = {
      amount: (params.toBePaid - (promocodeAmount ?? 0)) * 100,
      currency: restaurantData?.currencyName.toLowerCase(),
      paymentMethodId: paymentMethod?.id,
      tabId: tabDetails?.tabId.toString(),
      orderId: orderIds,
      posOrderId: posOrderIds,
      establishmentId: estId,
      establishmentName: restaurantData?.establishmentName,
      restaurantId: restaurantData?.restaurantId.toString(),
      restaurantName: restaurantData?.restaurantName,
    }
    completePayment(payload)
      .then(async (stripeResult) => {
        /* complete method needs to be called within 30 seconds of payment to complete */
        complete('success')
        try {
          params.data = {
            firstName:
              payerName !== null && payerName.includes(' ')
                ? payerName.trim().split(/\s+/)[0] !== ''
                  ? payerName.trim().split(/\s+/)[0]
                  : payerName
                : payerName,
            lastName:
              payerName !== null && payerName.includes(' ')
                ? payerName.trim().split(/\s+/)[1] !== ''
                  ? payerName.trim().split(/\s+/)[1]
                  : payerName
                : payerName,
            emailAddress: payerEmail,
            guestId,
          }
        } catch (error) {
          setLoaderForPaymentProcess(
            false,
            'Invalid payerName, payerEmail, or guestId'
          )
          guestService.addLogAsync(
            `Guest : Error in Complete Payment method ${error}`
          )
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (paymentOption === PAYMENT_OPTIONS.APPLE_PAY) {
          try {
            const payDetails = paymentService.paymentData.paymentMethod
            const toBePaidData: IGetToBePaidResponse = payToBeDoneRes || {
              total: subTotal + tax + serviceCharge + tip,
              totalBillAmount: subTotal,
              totalTax: tax,
              paymentToBeDones: null,
            }
            setServiceCharge(payToBeDoneRes?.totalServiceCharges)

            const paymentValidationReq = getValidationPayload()
            guestService.addLogAsync(
              `Payload paymentValidationReq generated ${
                tabDetails?.tabId
              } ${JSON.stringify(paymentValidationReq)}`
            )
            const processPaymentReq = {
              paymentOrder: buildPaymentOrder(
                toBePaidData.paymentToBeDones,
                toBePaidData.totalBillAmount,
                tip
              ),
              firstName: params.data.firstName || payerName,
              lastName: params.data.lastName || payerName,
              emailAddress: params.data.emailAddress,
              phoneNumber: params.data.phoneNumber,
              postalCode: payDetails?.billing_details?.address?.postal_code,
              guestId: params.data.guestId || 0,
              loginId: params.data.loginId,
              tabId: tabDetails?.tabId,
              paymentMethodId: 2,
              cardType: e.walletName,
              transactionId: stripeResult.intentId,
              paymentGatewayTxnId: stripeResult.chargeId,
              splitTypeName: processableSplitType,
              items: itemListPay,
              isCustomTip,
              tipPercentage,
              serviceCharge: Number(
                payToBeDoneRes.totalServiceCharges.toFixed(2)
              ),
              establishmentId: Number(estId),
              actionId: TRACKING_EVENTS.PAYMENT_COMPLETED,
              tableId: storageHelper.getTableId(),
              tabConnectionId: Number(
                sessionStorage.getItem('tabConnectionId')
              ),
              transactionType: 'Apple Pay',
              paymentDate: new Date(payDetails.created * 1000).toISOString(),
              accountNo: payDetails.card.last4,
              restaurantId: restaurantData?.restaurantId,
              orderId: params.paymentOrder[0].orderId,
              isAddGuestRequired: !user?.loginId || !params.data.guestId,
              posTableId: Number(sessionStorage.getItem('posTableId')),
              total: params.toBePaid,
              totalTip: Number(params.payTipAmount.toFixed(2)),
              totalTax: params.payTaxAmount,
              paymentModeId: Number(splitType),
              promoAmount: promocodeAmount,
              promocodeId,
              noOfPayingGuest,
            }

            guestService.addLogAsync(
              `Payload processPaymentReq generated ${
                tabDetails?.tabId
              } ${JSON.stringify(processPaymentReq)}`
            )

            let response

            try {
              response = await paymentService.processApplePayInFT({
                paymentProcessRequestDTO: processPaymentReq,
                paymentValidationRequestDTO: paymentValidationReq,
              })
            } catch (error) {
              // Log the retry attempt
              guestService.addLogAsync(
                `Guest : Added Second Time Payment Tab ${processPaymentReq.tabId}, Total: ${processPaymentReq.total}`
              )

              response = await paymentService.processApplePayInFT({
                paymentProcessRequestDTO: processPaymentReq,
                paymentValidationRequestDTO: paymentValidationReq,
              })
            }

            const result = response.data.data
            setLoaderForPaymentProcess(false, 'updatePaymentDetailInFasTab')
            localStorage.removeItem('IsPaymentProgress')
            if ((result as ITabPaymentItem).tabPaymentId) {
              navigate('/payment-successful', {
                state: {
                  data: params.data,
                  billNo: (result as ITabPaymentItem).billNo,
                  ammountpaid: (
                    params.toBePaid -
                    (promocodeAmount > 0 ? promocodeAmount : 0)
                  ).toFixed(2),
                  paymentId: (result as ITabPaymentItem).tabPaymentId,
                  guestId: (result as ITabPaymentItem).guestId,
                  establishmentId: Number(estId),
                  tabId: Number(tabsId),
                },
              })
            } else if ((result as IValidationRes).isError) {
              if (
                (result as IValidationRes)?.errorMessage ===
                'Order does not match.'
              ) {
                setOpenOrderDiffALertDialog(true)
                setOpenOrderDiffALertDialogMessage(true)
                setPaymentFailed(true)
                await tabService.setTableStatus({
                  isOutOfService: true,
                  tableId: storageHelper.getTableId(),
                })
              } else {
                setSplitMsg((result as IValidationRes)?.errorMessage)
                setOpenALertDialog(true)
              }
              onClose(null)
            }
          } catch (error) {
            guestService.addLogAsync(
              `Error in If Condition (paymentOption === PAYMENT_OPTIONS.APPLE_PAY) ${
                tabDetails?.tabId
              } ${JSON.stringify(error)}`
            )
          }
        } else {
          updatePaymentDetailInFasTab({
            ...params,
            intentId: stripeResult.intentId,
            chargeId: stripeResult.chargeId,
            description: stripeResult.description,
            cardType: e.walletName,
          })
        }
      })
      .catch(async (error) => {
        toast.error(
          'There was an issue with your payment. Please check with your server, and rest assured any charges will be refunded.'
        )

        guestService.addLogAsync(
          `Guest : Error in On Payment method ${JSON.stringify(error)}`
        )
        if (paymentOption === PAYMENT_OPTIONS.GOOGLE_PAY) {
          toast.error('Google Pay failed, please try again!', {
            toastId: 'gpayfail',
          })
        }
        setBtnClicked(false)
        setExpanded(false)
        setLoaderForPaymentProcess(false, 'Payment error')
        onClose(paymentOption)
      })
  }

  const payNow = (params: IPayment) => {
    if (stripe) {
      localStorage.setItem('IsPaymentProgress', 'true')
      const pr: PaymentRequest | undefined = paymentRequest
      let tokenInvoke = false
      if (pr) {
        pr.update({
          total: {
            label: 'Total Amount',
            amount: Math.round(
              (params.toBePaid - (promocodeAmount ?? 0)) * 100
            ),
          },
        })
        pr.on('paymentmethod', (e) => {
          console.log(`Payment method start`)
          SetLogs('Payment method start')
          onPaymentMethod(e, params)
        })
        pr.on('token', (e) => {
          tokenInvoke = true
        })
        pr.on('cancel', () => {
          console.log(`Payment cancel`)
          SetLogs('Payment method cancel')
          setBtnClicked(false)
          setExpanded(false)
          if (!tokenInvoke) {
            onClose(paymentOption)
          }
        })
        try {
          pr?.show()
        } catch (error) {
          console.log(`Show error ${JSON.stringify(error)}`)
          guestService.addLogAsync(
            `Guest : Show error ${JSON.stringify(error)}`
          )
          if (paymentOption === PAYMENT_OPTIONS.GOOGLE_PAY) {
            toast.error('Google Pay failed, please try again!', {
              toastId: 'gpayfail',
            })
          }
          setLoaderForPaymentProcess(false, 'Google pay error')
          onClose(paymentOption)
          SetLogs(`Show error ${JSON.stringify(error)}`)
          return false
        }
        return true
      }
    }
    return false
  }

  // eslint-disable-next-line consistent-return
  const handleSave = async (data) => {
    setLoaderForPaymentProcess(true, 'handleSaveStart')
    // process start
    setBtnClicked(true)
    const toBePaidData: IGetToBePaidResponse = payToBeDoneRes
    const paySubTotal = Number(toBePaidData.totalBillAmount.toFixed(2))
    const payTaxAmount = Number(toBePaidData.totalTax.toFixed(2))
    const totalServiceCharges = Number(
      toBePaidData.totalServiceCharges.toFixed(2)
    )
    const payTipAmount = tip

    const paymentData: IPayment = {
      data,
      intentId: '',
      chargeId: '',
      description: '',
      cardType: '',
      paySubTotal,
      payTaxAmount,
      payTipAmount,
      toBePaid: totalToBePaid,
      paymentOrder: buildPaymentOrder(
        toBePaidData.paymentToBeDones,
        toBePaidData.totalBillAmount,
        tip
      ),
    }

    /* Stripe Payment starts */
    const payThroughCard = async (billAmount: number, tabId: string) => {
      const stripeResult = await sendPaymentRequest(
        (billAmount - (promocodeAmount > 0 ? promocodeAmount : 0)) * 100,
        restaurantData?.currencyName.toLocaleLowerCase(),
        tabId
      )
      if (!stripeResult.intentId) {
        guestService.addLogAsync(
          `Method: payThroughCard. TabId:${
            tabDetails.tabId
          } , Stripe Result:${JSON.stringify(stripeResult)}`
        )
        toast.error(stripeResult.description)
        setLoaderForPaymentProcess(false, 'payThroughCard')
        dispatch(setPaymentInProcess(false))
        await tabService.lockTab({
          tabId: tabDetails?.tabId,
          isLock: false,
        })
        return null
      }
      return stripeResult
    }
    switch (paymentOption) {
      case PAYMENT_OPTIONS.CARD:
        // eslint-disable-next-line no-case-declarations
        const stripeResult = await payThroughCard(
          totalToBePaid,
          tabDetails?.tabId?.toString()
        )
        if (!stripeResult) {
          return false
        }

        updatePaymentDetailInFasTab({
          ...paymentData,
          intentId: stripeResult?.intentId,
          description: stripeResult?.description,
          cardType: stripeResult?.cardType,
          chargeId: stripeResult?.chargeId,
        })
        break
      case PAYMENT_OPTIONS.GOOGLE_PAY:
        try {
          if (!payNow(paymentData)) {
            return false
          }
        } catch (error) {
          toast.error('Google Pay failed, please try again!', {
            toastId: 'gpayfail',
          })
          guestService.addLogAsync(
            `Guest : Google pay failed: ${JSON.stringify(error)}`
          )
          setLoaderForPaymentProcess(false, 'Google pay error')
          onClose(paymentOption)
          return false
        }
        break
      default:
        return false
    }
    return true
    /* Stripe Payment ends */
  }

  const beginPaymentProcess = async (
    data: ITabsPaymentRequest,
    details: ITabDetail
  ) => {
    setBtnDisable(true)
    localStorage.setItem('IsPaymentProgress', 'true')
    if (data) {
      data.guestId = user ? user.guestId : null
    }

    toBePaidResponse = payToBeDoneRes
    setisPaymentProcessValid(true)
    await tabService.lockTab({
      tabId: tabDetails?.tabId,
      isLock: true,
    })
    const toBePaidData: IGetToBePaidResponse = toBePaidResponse || {
      total: subTotal + tax + serviceCharge + tip,
      totalBillAmount: subTotal,
      totalTax: tax,
      paymentToBeDones: null,
    }
    setServiceCharge(toBePaidResponse?.totalServiceCharges)
    paymentService.paymentOrder = buildPaymentOrder(
      toBePaidData.paymentToBeDones,
      toBePaidData.totalBillAmount,
      tip
    )
    setLoaderForPaymentProcess(false, 'open popup')
    return handleSave(data)
  }

  const handleApplePay = () => {
    setLoaderForPaymentProcess(true, 'handle Apple pay start')
    SetLogs('handle Apple pay start')
    const paymentData = getPaymentData()
    if (!payNow(paymentData)) {
      setBtnClicked(false)
      setExpanded(false)
      onClose(paymentOption)
    }
  }

  // eslint-disable-next-line consistent-return

  const handleChange =
    (panel: string) =>
    async (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
      switch (panel) {
        case PaymentOptionPanels.panel1:
          setPaymentOption(PAYMENT_OPTIONS.CARD)
          break
        case PaymentOptionPanels.panel2:
          setPaymentOption(PAYMENT_OPTIONS.GOOGLE_PAY)
          break
        case PaymentOptionPanels.panel3:
          setPaymentOption(PAYMENT_OPTIONS.APPLE_PAY)
          break

        default:
          setPaymentOption(null)
      }
    }

  const validatePayment = async (data: ITabsPaymentRequest) => {
    if (paymentOption === PAYMENT_OPTIONS.APPLE_PAY) {
      handleApplePay()
      return true
    }
    setLoaderForPaymentProcess(true, 'handleSave')
    setBtnClicked(true)
    // check is paid from pos
    console.log('Get config start')
    let ftAmount = 0
    const selectedOrders = tabDetails.orders.filter(
      (d) =>
        d.isSelected &&
        d.isOpen &&
        payToBeDoneRes.paymentToBeDones
          .map((x) => x.orderId)
          .includes(d.orderId)
    )

    const ordersForCompare: IOrderCompare[] = []
    for (const element of selectedOrders) {
      console.log('Orders Loop', element)

      ordersForCompare.push({
        orderId: element.orderId,
        posOrderId: element.posOrderId,
        totalServiceCharges: element.totalServiceCharges,
        omnivoreTicketNo: element.omnivoreTicketNo,
        items: element.items.map((el) => {
          return {
            itemId: el.itemId,
            posItemId: el.posItemId,
            name: el.name,
            quantity: el.quantity,
          } as IItemCompare
        }),
      })
      try {
        // eslint-disable-next-line no-loop-func
        const orderIndex = tabDetails?.tabPayments?.orderPayments.findIndex(
          (ele) => ele.orderId === element.orderId
        )
        if (orderIndex >= 0) {
          ftAmount +=
            tabDetails?.tabPayments?.orderPayments[orderIndex]
              ?.orderPaymentAmount
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Pay-Check: ', error)
        guestService.addLogAsync(`Guest :Pay-Check: ${error}`)
      }
    }
    const posOrderId =
      tabDetails?.orders
        ?.filter((d) => d.isSelected && d.isOpen)
        .map((order) => order.posOrderId) || []
    const payload = {
      itemIds: itemListPay?.map((d) => d.itemId),
      tabId: tabDetails?.tabId,
      isPaymentRequest: true,
      splitType: Number(sessionStorage.getItem('paymentModeId')),
      posOrderIds: posOrderId || [],
      establishmentId: Number(estId),
      posTableId: tabDetaildata.tableId,
      totalFTPaymentOnSelectedOrderIds: ftAmount,
      selectedOrders: ordersForCompare,
      noOfPayingGuest: noOfPayingGuest ?? 0,
    }

    // call validation api
    let validationRes
    try {
      validationRes = await paymentService.paymentValidation(payload)
      const result = validationRes.data.data
      if (result?.isError) {
        setLoaderForPaymentProcess(false, 'useEffect1')
        if (result?.errorMessage === 'Order does not match.') {
          setOpenOrderDiffALertDialog(true)
          setOpenOrderDiffALertDialogMessage(true)
          setPaymentFailed(true)
          await tabService.setTableStatus({
            isOutOfService: true,
            tableId: storageHelper.getTableId(),
          })
        } else {
          setSplitMsg(validationRes.data.data?.errorMessage)
          setOpenALertDialog(true)
        }
        onClose(null)
        return false
      }
    } catch (error) {
      setLoaderForPaymentProcess(false, 'useEffect1')
      guestService.addLogAsync(`Guest : Payment Validation Error ${error}`)
    }
    beginPaymentProcess(data, validationRes.data.data)

    return true
  }

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        emailAddress: yup
          .string()
          .required('Email is required')
          .email('Email is not valid'),
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
      }),
    []
  )

  const formik = useFormik({
    initialValues: getDefaultValue(),
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: validatePayment,
  })

  useEffect(() => {
    const isLiveCardValid =
      paymentOption === PAYMENT_OPTIONS.CARD &&
      !Object.keys(formik.errors).length &&
      formik.values.firstName &&
      formik.values.lastName &&
      formik.values.emailAddress &&
      !isCardNumberValid &&
      !isExpValid &&
      !isCvvValid &&
      stripe &&
      expanded !== false

    const isGooglePayOrApplePayValid =
      paymentOption === PAYMENT_OPTIONS.GOOGLE_PAY ||
      paymentOption === PAYMENT_OPTIONS.APPLE_PAY

    if (isLiveCardValid || isGooglePayOrApplePayValid) {
      setBtnDisable(false)
      if (paymentOption !== PAYMENT_OPTIONS.APPLE_PAY && !btnClicked) {
        setLoaderForPaymentProcess(false, 'useEffect1')
      }
    } else {
      setBtnDisable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.errors,
    formik.touched,
    expanded,
    btnClicked,
    paymentOption,
    stripe,
    isCardNumberValid,
    isExpValid,
    isCvvValid,
  ])

  const handlEmailFocus = () => {
    // scroll up on email focus
    const section = document.querySelector('#emailAddress')
    setTimeout(() => {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 100)
  }

  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog d-block"
        maxWidth="md"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={() => {
              setBtnClicked(false)
              setExpanded(false)
              onClose(paymentOption)
            }}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <p className="sub-title">Payment Method</p>
          {/* payment-options start */}
          <div className="payment-options">
            {availableOption === PAYMENT_OPTIONS.GOOGLE_PAY && (
              <Accordion
                expanded={expanded === PaymentOptionPanels.panel2}
                onChange={handleChange(PaymentOptionPanels.panel2)}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <input type="radio" id="google-pay" name="payment-option" />
                  <label htmlFor="google-pay">
                    <span>Google Pay</span>
                    <img src={GooglePay} alt="Google Pay" />
                  </label>
                </AccordionSummary>
              </Accordion>
            )}
            {availableOption === PAYMENT_OPTIONS.APPLE_PAY && (
              <Accordion
                expanded={expanded === PaymentOptionPanels.panel3}
                onChange={handleChange(PaymentOptionPanels.panel3)}
              >
                <AccordionSummary
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <input type="radio" id="apple-pay" name="payment-option" />
                  <label htmlFor="apple-pay">
                    <span>Apple Pay</span>
                    <img src={ApplePay} alt="Apple Pay" />
                  </label>
                </AccordionSummary>
              </Accordion>
            )}
            <Accordion
              expanded={expanded === PaymentOptionPanels.panel1}
              onChange={handleChange(PaymentOptionPanels.panel1)}
              className="card-payment"
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <input type="radio" id="card-pay" name="payment-option" />
                <label htmlFor="card-pay">
                  <span>Credit Card / Debit Card</span>
                  <img src={CreditCard} alt="Credit Card" />
                </label>
              </AccordionSummary>
              <AccordionDetails>
                <form onSubmit={formik.handleSubmit} id="paymentForm">
                  <div className="form-group">
                    <label className="form-lable" htmlFor="firstName">
                      First Name
                    </label>
                    <input
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      value={formik.values?.firstName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.setFieldValue('firstName', e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <span className="error-text">
                      {!!formik.errors.firstName && formik.touched.firstName
                        ? formik.errors.firstName
                        : ''}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-lable" htmlFor="lastName">
                      Last Name
                    </label>
                    <input
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      value={formik.values?.lastName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.setFieldValue('lastName', e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <span className="error-text">
                      {!!formik.errors.lastName && formik.touched.lastName
                        ? formik.errors.lastName
                        : ''}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-lable" htmlFor="emailAddress">
                      Email
                    </label>
                    <input
                      id="emailAddress"
                      name="emailAddress"
                      placeholder="Email"
                      className="form-control"
                      onFocus={handlEmailFocus}
                      autoComplete="off"
                      type="text"
                      value={formik.values?.emailAddress}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.setFieldValue('emailAddress', e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <span className="error-text">
                      {!!formik.errors.emailAddress &&
                      formik.touched.emailAddress
                        ? formik.errors.emailAddress
                        : ''}
                    </span>
                  </div>
                  <div className="form-group card-input">
                    <StripeCardNumber
                      label="Card Number"
                      placeholder="Card Number"
                      setBtnDisable={setisCardNumberValid}
                    />
                  </div>
                  <div className="form-group">
                    <StripeCardExpiry
                      setBtnDisable={setIsExpValid}
                      label="Expiry"
                      placeholder="MM/YYYY"
                    />
                  </div>
                  <div className="form-group">
                    <StripeCardCVV
                      setBtnDisable={setIsCvvValid}
                      label="CVV"
                      placeholder="CVV"
                    />
                  </div>
                </form>
              </AccordionDetails>
            </Accordion>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            {(paymentOption === PAYMENT_OPTIONS.GOOGLE_PAY ||
              paymentOption === PAYMENT_OPTIONS.APPLE_PAY) && (
              <Button
                id="checkoutButton"
                color="primary"
                disabled={btnDisable}
                variant="contained"
                onClick={() => validatePayment(null)}
                title={`Pay now $${(
                  paymentAmount - (promocodeAmount > 0 ? promocodeAmount : 0)
                ).toFixed(2)}`}
              >
                <span>
                  Pay now $
                  {(
                    paymentAmount - (promocodeAmount > 0 ? promocodeAmount : 0)
                  ).toFixed(2)}
                </span>
              </Button>
            )}
            {paymentOption === PAYMENT_OPTIONS.CARD && (
              <Button
                id="checkoutButton"
                color="primary"
                variant="contained"
                type="submit"
                form="paymentForm"
                disabled={btnDisable}
                title={`Pay now ${(
                  paymentAmount - (promocodeAmount > 0 ? promocodeAmount : 0)
                ).toFixed(2)}`}
              >
                <span>
                  Pay now $
                  {(
                    paymentAmount - (promocodeAmount > 0 ? promocodeAmount : 0)
                  ).toFixed(2)}
                </span>
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={openAlertDialog}
        onClose={() => setOpenALertDialog(false)}
        splitMsg={splitMsg}
      />
      <OrderDiffAlertDialog
        open={openOrderDiffAlertDialog}
        messageAvailable={openOrderDiffAlertDialogMesssage}
        onClose={() => setOpenOrderDiffALertDialog(false)}
        paymentFailed={paymentFailed}
      />
    </>
  )
}

export default PaymentProcessDialog
